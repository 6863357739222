module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf386c0d91218ef7bcb76635dba107b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QX5KCWS6K5"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","es"],"defaultLanguage":"en","siteUrl":"https://www.bluetrail.software","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","es"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/refer-a-friend","languages":["en"]},{"matchPath":"/sitemap","languages":["en"]},{"matchPath":"/security","languages":["en"]},{"matchPath":"/terms-of-service","languages":["en"]},{"matchPath":"/privacy-policy","languages":["en"]},{"matchPath":"/careers/jobs/:description","languages":["en"]},{"matchPath":"/careers/:location","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
